/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

(function ( $ ) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // //ISOTOPE
                // $(window).load(function () {
                //     $('.cateringWrapper').isotope({
                //         // options
                //         itemSelector: '.cateringBlock',
                //         layoutMode: 'masonry'
                //     });
                // });


                var $container = $('.cateringWrapper');
                $container.imagesLoaded( function(){
                    $container.isotope({
                        itemSelector: '.cateringBlock',
                        layoutMode: 'masonry'
                    });
                });


                //Check to see if the window is top if not then display button
                $( window ).scroll( function () {
                    if ( $( this ).scrollTop() > 159 ) {

                        if ( !$( '.navbar' ).hasClass( 'is-sticky' ) ) {
                            $( '.navbar' ).addClass( ' is-sticky' );
                        }

                    } else if ( $( this ).scrollTop() < 159 ) {

                        if ( $( '.navbar' ).hasClass( 'is-sticky' ) ) {

                            $( '.navbar' ).removeClass( 'is-sticky' );
                        }

                    }
                } );



                // init slicknav
                $( "#menu" ).slicknav( {
                    label: ''
                } );

                // add logo to slicknav
                $( '.slicknav_menu' ).prepend( $( ".logo" ).clone() );

                // create custom selectboxes
                $( 'select' ).select2();

                // auto complete
                $( '.search .search-city' ).autocomplete( {
                    serviceUrl: ajax_url,
                    params: {
                        'action': 'my_autocomplete',
                        'activeTerm': $( "#activeTerm" ).val()
                    },
                    forceFixPosition: true,
                    onSelect: function ( suggestion ) {
                        window.location = suggestion.data.url;
                    },
                    onSearchStart: function ( query ) {
                    }
                } );


                $( '.menu li a' ).on( 'click', function ( e ) {
                    if ( $( this ).attr( 'data-id' ) !== "socialmedia" ) {
                            e.preventDefault();

                            if ( $( this ).attr( 'data-id' ) === "home" ) {
                            $( 'html, body' ).animate( {
                                scrollTop: $( "#" + $( this ).attr( 'data-id' ) ).offset().top - 50
                            }, 750 );

                        } else {
                            $( 'html, body' ).animate( {
                                scrollTop: $( "#" + $( this ).attr( 'data-id' ) ).offset().top - 150
                            }, 750 );
                            console.log();

                            $( '.navigation li' ).removeClass( 'white' );
                            return false;
                        }
                    }
                } );

                $( 'ul.slicknav_nav li a' ).on( 'click', function ( e ) {
                    e.preventDefault();
                    if ( $( this ).attr( 'data-id' ) === "home" ) {


                        $( 'html, body' ).animate( {
                            scrollTop: $( "#" + $( this ).attr( 'data-id' ) ).offset().top - 50
                        }, 750 );

                    } else {
                        $( 'html, body' ).animate( {
                            scrollTop: $( "#" + $( this ).attr( 'data-id' ) ).offset().top - 150
                        }, 750 );
                        console.log();

                        $( '.navigation li' ).removeClass( 'white' );
                        return false;
                    }
                } );



            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired




            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function ( func, funcname, args ) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if ( fire ) {
                namespace[func][funcname]( args );
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire( 'common' );

            // Fire page-specific init JS, and then finalize JS
            $.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function ( i, classnm ) {
                UTIL.fire( classnm );
                UTIL.fire( classnm, 'finalize' );
            } );

            // Fire common finalize JS
            UTIL.fire( 'common', 'finalize' );
        }
    };

    // Load Events
    $( document ).ready( UTIL.loadEvents );

})( jQuery ); // Fully reference jQuery after this point.